import { Box, Button, Grid, Stack, Typography, styled } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

// icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// components
import Fragranze from "../../components/fragranze";
import Personalizza from "../../components/personalizza";
import SalvaFreschezza from "../../components/salvafreschezza";
import Hero from "../../utils/hero";
import Link from "../../utils/link";
import Page from "../../utils/page";
import Section from "../../utils/section";
import theme from "../../utils/theme";

const WhiteButton = styled(Button)(({ theme }) => ({
  border: "none",
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.getContrastText(theme.palette.primary.main),
  "&:hover": {
    border: "none",
    backgroundColor: theme.palette.grey[200],
  },
}));

const HomePage = () => {
  return (
    <Page
      lang="es"
      title="Toallitas perfumadas desechables"
      description="Desde 1998 producimos toallitas perfumadas desechables. Nuestras toallitas húmedas individuales son fabricadas con un tejido especial que las hace resistentes y al mismo tiempo suaves para la piel."
      type="WebPage"
      readMore={["/toallitas-desechables"]}
      image="/home.jpg"
    >
      <Hero
        title="Desde 1998 producimos toallitas perfumadas desechables para el mundo Ho.Re.Ca"
        description={
          <>
            Nuestras <strong>toallitas húmedas individuales</strong> están
            fabricadas con un tejido especial que las hace{" "}
            <strong>resistentes</strong> y al mismo tiempo{" "}
            <strong>suaves para la piel</strong>. Son ideales para limpiarse las
            manos al final de una comida.
            <Box pt={4}>
              <Link
                type="internal"
                href="/es/toallitas-desechables"
                title="Descubre más sobre las toallitas"
              >
                <WhiteButton size="large" variant="contained" color="primary">
                  Nuestras toallitas
                </WhiteButton>
              </Link>
            </Box>
          </>
        }
        background={theme.palette.primary.main}
        backgroundImage={
          <StaticImage
            style={{ height: "100%" }}
            quality={100}
            src="../../images/backgrounds/fragranza-menta.jpg"
            alt="Fragancia menta"
            layout="fullWidth"
          />
        }
        image={
          <StaticImage
            imgStyle={{ borderRadius: 16 }}
            src="../../images/products/salviette-monouso-per-la-ristorazione-mettifogo.png"
            alt="Toallitas desechables para bares, restaurantes y hoteles"
            layout="fullWidth"
          />
        }
      />

      <Fragranze language="es" />
      <SalvaFreschezza language="es" />

      <Section
        maxWidth="md"
        background={theme.palette.primary.main}
        backgroundImage={
          <StaticImage
            style={{ height: "100%" }}
            quality={100}
            src="../../images/backgrounds/fragranza-menta.jpg"
            alt="Fragancia menta"
            layout="fullWidth"
          />
        }
      >
        <Stack spacing={2} textAlign="center">
          <Typography variant="h3" component="h2">
            Toallitas perfumadas desechables,
            <br /> perfectas para bares, restaurantes y hoteles
          </Typography>
          <Typography>
            Descubre las diferentes fragancias y encuentra la que mejor se
            adapta a tu local, bar o restaurante.
          </Typography>

          <Box>
            <Link
              type="internal"
              href="/es/toallitas-desechables"
              title="Descubre más sobre las toallitas"
            >
              <Button size="large" variant="contained">
                Más información
              </Button>
            </Link>
          </Box>
        </Stack>
      </Section>

      <Section>
        <Grid
          spacing={6}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} md={5}>
            <StaticImage
              imgStyle={{ borderRadius: 16 }}
              src="../../images/mettifogo-produttore-salviette-personalizzate.jpg"
              alt="Envase de protección"
              layout="fullWidth"
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Stack spacing={1}>
              <Typography variant="h3" component="h3">
                Sobre nosotros
              </Typography>
              <Typography>
                En 1989 comenzamos a producir{" "}
                <strong>productos personalizados para la restauración</strong>,
                añadiendo en el año 1998 la línea de producción para las
                toallitas húmedas desechables.
              </Typography>
              <Typography>
                Partiendo de las clásicas toallitas de limón, hemos creado una
                amplia gama de fragancias.
              </Typography>
              <Typography>
                Nuestras <strong>toallitas húmedas individuales</strong> han
                sido diseñadas para durar en el tiempo, gracias a la calidad de
                la envoltura exterior, estudiada para conservar la humedad y el
                perfume de la fragancia.
              </Typography>
              <Box>
                <Link
                  type="internal"
                  href="/es/sobre-nosotros"
                  title="Descubre más sobre nosotros"
                >
                  <Button
                    style={{ padding: 8, marginLeft: -8 }}
                    size="large"
                    variant="text"
                    color="info"
                    endIcon={<ArrowForwardIcon />}
                  >
                    Descubre más
                  </Button>
                </Link>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Section>

      <Personalizza language="es" />
    </Page>
  );
};

export default HomePage;
