import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

// icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// components
import Link from "../utils/link";
import Section from "../utils/section";

interface FragranzeProps {
  language?: "it" | "en" | "es";
}

const Fragranze = ({ language }: FragranzeProps) => {
  const content = {
    it: {
      title: "Fragranze salviette monouso",
      description:
        "Nel corso degli anni abbiamo selezionato, studiato e sviluppato 7 tipologie di fragranze ideali per ogni tipo di realtà, dal ristorante di pesce al Bed&Breakfast. Puoi scegliere per la tua attività la fragranza che più si addice tra le seguenti profumazioni:",
      fragrances: [
        "Limone",
        "Lime e cannella",
        "Mojito",
        "Coloniale (mirra e bergamotto)",
        "Lavanda e camomilla",
        "Tè verde",
        "Sweet fruit (arancia e mela)",
      ],
      additionalInfo:
        "Le nostre salviettine monouso sono state progettate per soddisfare diversi requisiti tra cui l'eliminazione degli odori poco gradevoli dalla pelle come quelli del pesce, ed allo stesso tempo per rinfrescare le mani alla fine del pasto.",
      button: "Scopri di più",
      link: "/salviette-monouso",
    },
    en: {
      title: "Fragrance disposable wipes",
      description:
        "Over the years we have selected, researched and developed 7 types of fragrances that are ideal for every type of business, from seafood restaurants to Bed&Breakfasts. You can choose the fragrance that suits your business best from the following scents:",
      fragrances: [
        "Lemon",
        "Lime and Cinnamon",
        "Mojito",
        "Colonial (myrrh and bergamot)",
        "Lavender & Chamomille",
        "Green tea",
        "Sweet fruit (orange & apple)",
      ],
      additionalInfo:
        "Our disposable wipes are designed to meet several requirements including removing unpleasant odors from the skin such as fish odors, and at the same time refreshing the hands at the end of the meal.",
      button: "Learn more",
      link: "/en/wipes",
    },
    es: {
      title: "Fragancias toallitas desechables",
      description:
        "Con los años hemos seleccionado, estudiado y desarrollado 7 tipos de fragancias ideales para cada tipo de realidad, desde el restaurante de pescado hasta el Bed&Breakfast. Puedes elegir la fragancia que mejor se adapte a tu negocio entre las siguientes fragancias:",
      fragrances: [
        "Limón",
        "Lime y canela",
        "Mojito",
        "Colonial (mirra y bergamota)",
        "Lavanda y manzanilla",
        "Té verde",
        "Sweet fruit (naranja y manzana)",
      ],
      additionalInfo:
        "Nuestras toallitas desechables han sido diseñadas para cumplir con diferentes requisitos, incluyendo la eliminación de olores desagradables de la piel como los del pescado, y al mismo tiempo refrescar las manos al final de la comida.",
      button: "Descubre más",
      link: "/es/toallitas-desechables",
    },
  };

  const selectedContent = content[language || "it"];

  return (
    <Section>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} md={4}>
          <StaticImage
            imgStyle={{ borderRadius: 16 }}
            src="../images/fragranze.jpg"
            alt={selectedContent.title}
            layout="fullWidth"
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <Stack spacing={2}>
            <Typography variant="h3" component="h2">
              {selectedContent.title}
            </Typography>
            <Typography>{selectedContent.description}</Typography>
            <ul>
              {selectedContent.fragrances.map((fragrance, index) => (
                <li key={index}>{fragrance}</li>
              ))}
            </ul>
            <Typography>{selectedContent.additionalInfo}</Typography>
            <Box>
              <Link
                type="internal"
                href={selectedContent.link}
                title={selectedContent.button}
              >
                <Button
                  style={{ padding: 8, marginLeft: -8 }}
                  size="large"
                  variant="text"
                  color="info"
                  endIcon={<ArrowForwardIcon />}
                >
                  {selectedContent.button}
                </Button>
              </Link>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Section>
  );
};

export default Fragranze;
